<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Online kocsmai verekedések és bűnbakok 
          </h2>
          <p class="hashText">- Csoportokra, közösségekre célzott cyberbullying</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="10" class="side-gap offset-lg-1">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Ahogy „A csoportosan elkövetett bullyingról”  szóló cikkben is kiemeltük, az internetes zaklatás nemcsak egyénekre irányulhat, hanem csoportokra, sőt elképzelésekre vagy hiedelmekre is. Az online posztok, mémek, videók és kommentek olyan csoportokat, ötleteket vagy akár identitásformáló tényezőket is megcélozhatnak, mint egy vallási közösség, egy bizonyos politikai álláspont vagy egy nézet.</p>
                <h3>Amikor az énből mi leszünk</h3>
                <p class="text">
                Az online csoportok és közösségek lehetővé teszik az emberek számára, hogy kapcsolatot teremtsenek másokkal, akikkel közös érdeklődési körök, nézetek, élmények és tapasztalatok kötik össze őket. Az ilyen kapcsolatok erősítik a társadalmi kötelékeket, és fontosak az egyéni identitás kialakításában és fenntartásában, hiszen ezen csoportokba való tartozás mentén is definiáljuk magunkat, és az adott csoportokat ért dicsőségek és sérelmek mentén erősíthetjük vagy gyengíthetjük a saját magunkról alkotott pozitív képet. Gondoljunk csak egy focicsapat rajongóira! Milyen felemelő közösségi érzés az, amikor a hőn szeretett csapat nyer és a tömeg üdvrivalgásban tör ki. Ezt a jelenséget hívjuk BIRG-nek: basking in reflected glory, magyarul a visszavetülő fényben való sütkérezés. </p>
                <h3>A közösségekhez való tartozás árnyoldala</h3>
                <p class="text">Az ilyen erős közösségi érzések azonban könnyen a visszájukra sülhetnek el. Az online bántalmazás megjelenése az ilyen közösségekben, csoportokban súlyosan megzavarhatja a társadalmi kapcsolatokat és negatív hatással lehet a tagok mentális egészségére is. Az online bántalmazás sok formában megjelenhet, beleértve a fenyegetéseket, a zaklatást, a gyűlöletbeszédet, a rágalmazást és az álhírek terjesztését is. Az ilyen típusú bántalmazás áldozatai gyakran érzik magukat kiszolgáltatottnak és magányosnak, és nehéz lehet szembeszállniuk az elkövetőkkel még akkor is, ha a sérelem egy egész csoportot érint! A magány nem kifejezetten egyedüllétként értendő ilyenkor, sokkal inkább egy kiszakítottság, talajvesztettség érzését takarja, és nem semlegesíti a tény, hogy mások is szenvednek tőle. Az online csoportok és közösségek különösen veszélyeztetettek lehetnek az ilyen bántalmazások esetén, mivel az elkövetők gyakran csoportosan lépnek fel, és az áldozatot a közösségen belül kirekesztik vagy stigmatizálják. Így tehát beszélhetünk csoport által elkövetett erőszakról két dimenzióban is. </p>
               <h3>A csoportok által elkövetett bántalmazás dimenziói</h3>
                <p class="text">
               Az első dimenzió a csoport által egy egyén ellen elkövetett bántalmazás, melynek legmagátólértetődőbb módja a megkülönböztetés, majd a kirekesztés. Létrejöhetnek egy személy ellen is gyűlöletcsoportok, melynek szervező elve az adott ember iránti ellenszenv. Tudtad, hogy Lady Gagának is létrehoztak egy ilyen csoportot még karrierje legelején, az eredeti nevével fémjelzett „Stefani Germanotta soha nem leszel híres” címmel? Ez kifejezetten egy troll akciónak mondható, és a bullying nagyon sok formáját alkalmazza. </p>
               <p class="text">A második dimenzió a csoportok által csoportok ellen elkövetett bántalmazás, mely az offline kocsmai verekedésekhez hasonlóan egy hirtelen kirobbanó, hosszú és személyeskedő kommentfolyam (lángháború) vagy éppen az áldozati csoport gyűlöletén alapuló közösség létrehozásában nyilvánul meg. </p>
               <p class="text">Az online csoportok és közösségek vezetőinek és tagjainak fontos szerepe van abban, hogy megakadályozzák az online bántalmazás megjelenését és kezeljék a problémát, ha az már bekövetkezett. Az ilyen közösségeknek lehetnek szabályaik és protokolljaik az online viselkedésre vonatkozóan, és rendelkezniük kell hatékony módszerekkel az ilyen bántalmazások jelentésére és kezelésére. Az is fontos, hogy az online csoportok és közösségek tagjai kölcsönösen támogassák egymást, és meggyőződjenek róla, hogy az áldozat biztonságban érzi magát és van kihez fordulnia. </p>
            </div>
            </b-col>
            <b-col cols="12" lg="10" class="order-4 order-lg-1 offset-lg-1">
              <img src="images/negyes.jpg" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Online kocsmai verekedések és bűnbakok ", 
        desc: "Ahogy „A csoportosan elkövetett bullyingról”  szóló cikkben is kiemeltük, az internetes zaklatás nemcsak egyénekre irányulhat, hanem csoportokra, sőt elképzelésekre vagy hiedelmekre is. ", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Online kocsmai verekedések és bűnbakok ",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Ahogy „A csoportosan elkövetett bullyingról”  szóló cikkben is kiemeltük, az internetes zaklatás nemcsak egyénekre irányulhat, hanem csoportokra, sőt elképzelésekre vagy hiedelmekre is. "        
    })
  }
}
</script>